import { Injectable } from '@angular/core';
import { HttpService } from '../backend/common/api/http.service';
import { Segment } from '../interfaces/business/segment';
import { Scenario } from '../interfaces/business/scenario';
import { BaseCacheService } from './base-cache.service';

export interface IGetBusinessDriverParams {
  segment?: Segment;
  mainDim?: string;
  mainKPI?: string;
  secondaryDim?: string;
  secondaryKPI?: string;
  simcelInputDb?: string;
  simcelOutputDb?: string;
  startDate?: string;
  endDate?: string;
  highlightedScenario?: Scenario;
  top?: number;
  by?: number;
  duration?: number;
  planId?: string;
  currency?: string;
  highlightedScenarioId?: string;
}


export interface IGetBusinessDriverLoadedParams extends IGetBusinessDriverParams {
  displayedScenarios?: string[];
}

export interface BusinessDriverData {
  [key: string]: number | string;
}

@Injectable({ providedIn: 'root' })
export class BusinessDriverService extends BaseCacheService {
  constructor(private readonly httpService: HttpService) {
    super();
  }

  getBusinessDriver(params: IGetBusinessDriverParams) {
    return this.cacheOrFetch('business-driver', params, (endpoint, params) => this.httpService.post(endpoint, params));
  }
}

