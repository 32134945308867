import { createAction, props } from '@ngrx/store';
import { PlanSetting } from '../../app/@core/interfaces/business/plan-setting';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';

export const LOAD_PLAN_SETTING = createAction(
  '[⚡ Plan Setting API] Load Plan Setting',
  props<{ planId: string }>(),
);
export const LOAD_PLAN_SETTING_SUCCESS = createAction(
  '[🤖 Plan Setting API] Load Plan Setting success',
  props<{ planSetting: PlanSetting }>(),
);
export const LOAD_PLAN_SETTING_FAILED = createAction(
  '[🤖 Plan Setting API] Load Plan Setting failed',
  props<{ error: any }>(),
);

export const ADD_DISPLAYED_SCENARIO_SUCCESS = createAction(
  '[🤖 Plan Setting API] Add Displayed Scenario success',
  props<{ planSetting: PlanSetting, updatedScenario: any }>(),
);


export const ADD_DISPLAYED_SCENARIO_FAILED = createAction(
  '[🤖 Plan Setting API] Add Displayed Scenario failed',
  props<{ error: any }>(),
);
export const DELETE_DISPLAYED_SCENARIO_SUCCESS = createAction(
  '[🤖 Plan Setting API] Delete Displayed Scenario success',
  props<{ planSetting: PlanSetting, updatedScenario?: any }>(),
);
export const DELETE_DISPLAYED_SCENARIO_FAILED = createAction(
  '[🤖 Plan Setting API] Delete Displayed Scenario failed',
  props<{ error: any }>(),
);
export const HIDE_DISPLAYED_SCENARIO_SUCCESS = createAction(
  '[🤖 Plan Setting API] Hide Displayed Scenario success',
  props<{ planSetting: PlanSetting, updatedScenario: any }>(),
);
export const HIDE_DISPLAYED_SCENARIO_FAILED = createAction(
  '[🤖 Plan Setting API] Hide Displayed Scenario failed',
  props<{ error: any }>(),
);

export const CHANGED_DISPLAYED_SCENARIO_SUCCESS = createAction(
  '[🤖 Plan Setting API] Change Displayed Scenario success',
  props<{ planSetting: PlanSetting, updatedScenario?: any }>(),
);

export const CHANGED_DISPLAYED_SCENARIO_FAILED = createAction(
  '[🤖 Plan Setting API] Change Displayed Scenario failed',
  props<{ error: any }>(),
);

export const CHANGED_DISPLAYED_DATERANGE_SUCCESS = createAction(
  '[🤖 Plan Setting API] Changed Displayed Date Range success',
  props<{ planSetting: PlanSetting, displayedDateRange?:SimpleDateRange }>(),
);

export const CHANGED_DISPLAYED_DATERANGE_FAILED = createAction(
  '[🤖 Plan Setting API] Changed Displayed Date Range failed',
  props<{ error: any }>(),
);

export const CHANGED_USING_Y2Y_SUCCESS = createAction(
  '[🤖 Plan Setting API] Changed UsingY2Y success',
  props<{ planSetting: PlanSetting, usingY2Y?: boolean }>(),
);

export const CHANGED_USING_Y2Y_FAILED = createAction(
  '[🤖 Plan Setting API] Changed UsingY2Y failed',
  props<{ error: any }>(),
);

export const CHANGED_COMPARE_MODE_SUCCESS = createAction(
  '[🤖 Plan Setting API] Changed Compare Mode success',
  props<{ planSetting: PlanSetting, compareMode?: string }>(),
);

export const CHANGED_COMPARE_MODE_FAILED = createAction(
  '[🤖 Plan Setting API] Changed Compare Mode failed',
  props<{ error: any }>(),
);

export const CHANGED_SELECTED_KPIS_SUCCESS = createAction(
  '[🤖 Plan Setting API] Changed Selected Kpis success',
  props<{ planSetting: PlanSetting, selectedKpis?: any[] }>(),
);

export const CHANGED_SELECTED_KPIS_FAILED = createAction(
  '[🤖 Plan Setting API] Changed Selected Kpis failed',
  props<{ error: any }>(),
);

export const SET_LAST_SCENARIO_ACTION_TYPE = createAction(
  '[🤖 Plan Setting API] Set Last Scenario Action Type',
  props<{ actionType: string }>()
);
